/* global Component */
class promotionPage extends Component {

    static name() {
        return "promotionPage";
    }

    static componentName() {
        return "promotionPage";
    }

    getWatch() {
        return {
            "$route": function (to, from){
                this.fetchData();
            },
            "$store.state.itemTimeFlag": function (newValue, oldValue){
                this.filteredItems = this.$store.getters.getItemsVisibles;
            },
            'readyToLoad':function (newValue, oldValue){
                if(newValue) {
                    this.fetchData();
                }
            }
        };
    }

    data() {
        return {
            loaded:false,
            images:[]
        };
    }

    getMethods(){
        return{
            fetchData:this.fetchData
        };
    }
    getComputed() {
        return {
            promotionCode: function (){
                return this.$route.params.code;
            },
            readyToRender: function () {
                setTimeout(()=>{
                    $('.carousel').carousel({
                        interval: 8000
                    });
                },5000);
                return this.loaded &&  this.$store.state.itemsFullLoader && this.$store.state.appState == 'ready';
            },
            readyToLoad: function () {
                return this.$store.state.itemsFullLoader && this.$store.state.appState == 'ready';
            },
        };
    }

    mounted(){
        return async function (){
            if(!this.$route.params.code)
                this.$router.push("/home");
            else {
                if(this.readyToLoad)
                    this.fetchData();
                else{
                    this.$store.dispatch('toogleLockScreen',this.tr('Load promotion items'));
                }
            }
        };
    }

    fetchData(){
        if(!this.$store.state.screenState)
            this.$store.dispatch('toogleLockScreen',this.tr('Load promotion items'));
        this.$store.dispatch('loadPromotionInfo',{code:this.promotionCode}).then((images)=>{
            this.images =  images;
            this.$store.dispatch('toogleLockScreen');
            this.loaded = true;
        });
    }


    getTemplate() {
        return `<div :key="'promotion--'+promotionCode">
                  <headerSectionComponent></headerSectionComponent>
                  <div class="container-fluid">
                      <div class="section-main">
                            <div class="row">
                              <div class="col-sm-3 col-md-2 col-xs-12 mb-2 mb-sm-0 pt-0 px-2 pb-2 p-sm-2 pl-xl-3 pr-xl-4 filters-section">
                                  <homeCategorySectionComponent :only_icons_view=true></homeCategorySectionComponent>
                                  <categorySectionComponent :categories=false></categorySectionComponent>
                              </div>
                              <div class="col-sm-9 col-md-10 col-xs-12 pt-2 pt-sm-0 px-0 px-md-3">
                                  <div class="col-12" v-if="readyToRender">
                                    <div class="col-12" v-if="images.length">
                                        <div id="carouselPromotion" class="carousel slide" data-ride="carousel">
                                            <ol class="carousel-indicators">
                                                <template v-for="(image, index) of images">
                                                    <li data-target="#carouselPromotion" :data-slide-to="index" :class="{ active: index === 0 }"></li>
                                                </template>
                                            </ol>
                                            <div class="carousel-inner">
                                                <template v-for="(image, index) of images">
                                                    <div class="carousel-item" :class="{ active: index === 0 }">
                                                      <img class="d-block" :src="image" alt="Promotion Image" style="max-height: 300px;">
                                                    </div>
                                                </template>
                                            </div>
                                        </div>
                                    </div>
                                    <itemsSectionComponent :items="$store.items" :items_in_car="$store.items_in_car" :cat_history="$store.cat_history"></itemsSectionComponent>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
                  <footerSectionComponent></footerSectionComponent>
                </div>`;
    }
}

promotionPage.registerComponent();